<!-- Copyright (C) 2017, 2018 DecElite LLC - All Rights Reserved. May not be distributed or copied. -->
<template>
  <v-layout justify-space-around>
    <v-flex class="subcard">
      <v-card v-if="processState === 'form'">
        <v-card-text>
          <v-container
            fluid
            grid-list-lg
            class="py-0">
            <v-layout
              row
              wrap>
              <v-flex xs12>
                <div class="headline">{{ headline }}</div>
              </v-flex>
              <v-flex
                d-flex
                xs12
                v-if="loading">
                <!-- LOADING: stripe, user state, etc -->
                <v-progress-circular
                  indeterminate
                  :size="50"
                  class="primary--text"/>
              </v-flex>
              <v-flex
                xs9
                v-if="need">
                <!-- NEED -->
                <p>
                  {{ needMessage }}
                  Why not sign up right now?
                </p>
              </v-flex>
              <v-flex xs12>
                <span class="title">Subscription Plan</span>
              </v-flex>
              <v-flex
                v-if="!showFree"
                xs6
                child-flex
                d-flex>
                <v-card
                  dark
                  color="primary" >
                  <v-card-text>
                    <span class="title">Yearly (Save 18%)</span>
                    <v-radio-group
                      class="pa-0"
                      hide-details
                      v-model="targetPlan">
                      <v-radio
                        label="$100 per Year"
                        value="yearly-v1"/>
                    </v-radio-group>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex
                v-if="!showFree"
                xs6
                child-flex
                d-flex>
                <v-card
                  dark
                  class="grey darken-1">
                  <v-card-text>
                    <span class="title">Monthly</span>
                    <v-radio-group
                      class="pa-0"
                      hide-details
                      v-model="targetPlan">
                      <v-radio
                        label="$10 per Month"
                        value="monthly-v1"/>
                    </v-radio-group>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex
                v-if="showFree"
                xs12
                sm6
                child-flex
                d-flex>
                <v-card
                  dark
                  class="grey darken-1">
                  <v-card-text>
                    <span class="title">Professional Plan</span>
                    <v-radio-group
                      class="pa-0"
                      hide-details
                      v-model="targetPlan">
                      <v-radio
                        label="$100 per Year (Save 18%)"
                        value="yearly-v1"/>
                      <v-radio
                        label="$10 per Month"
                        value="monthly-v1"/>
                    </v-radio-group>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex
                v-if="showFree"
                xs12
                sm6
                child-flex
                d-flex>
                <v-card dark>
                  <v-card-text class="text-xs-left">
                    <span class="title">Free Plan</span>
                    <v-radio-group
                      class="pa-0"
                      hide-details
                      v-model="targetPlan">
                      <v-radio
                        hide-details
                        label="Free"
                        dark
                        value="free"/>
                    </v-radio-group>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12>
                <v-card>
                  <v-toolbar card>
                    <v-toolbar-title>Credit Card</v-toolbar-title>
                    <v-spacer/>
                    <v-toolbar-items v-if="hasCC">
                      <v-btn
                        flat
                        disabled>Change Card</v-btn>
                      <v-switch v-model="changeCC"/>
                    </v-toolbar-items>
                  </v-toolbar>
                  <!-- Current if present -->
                  <v-card-text
                    v-if="!ccField"
                    class="subheading">
                    <v-icon large>credit_card</v-icon>
                    <b>Saved Card:</b>
                    {{ userCard.brand }} x{{ userCard.last4 }},
                    Expires {{ userCard.exp_month }}/{{ userCard.exp_year }}
                  </v-card-text>
                  <v-card-text v-show="ccField">
                    <!-- CC ENTRY -->
                    <div id="ccadd"/>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12>
                <v-checkbox
                  hide-details
                  required
                  class="pt-0"
                  label="I accept the Terms of Service and Privacy Policy"
                  v-model="acceptTOS"
                  light/>
              </v-flex>
            </v-layout>

          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            v-if="inDialog"
            class="red--text darken-1"
            @click.native="reset(); $emit('input', false)">Cancel</v-btn>
          <v-btn
            v-else
            class="red--text darken-1"
            :disabled="!hasChange"
            @click.native="reset(); $emit('input', false)">Reset</v-btn>
          <!-- Disable if there are errors -->
          <v-btn
            class="green--text darken-1"
            @click.native="checkout = true"
            :disabled="hasError || !hasChange">
            Checkout
          </v-btn>
        </v-card-actions>
        <v-alert
          type="error"
          class="my-0"
          :value="hasError">{{ errorMsg }}</v-alert>
        <v-alert
          type="info"
          class="my-0"
          :value="currentMessage">{{ currentMessage }}</v-alert>
      </v-card>
      <v-card v-if="processState === 'checkout'">
        <v-card-text>
          <h2 class="display-1 grey--text text--darken-2">Summary</h2>
          <invoice
            v-if="targetPlan !== nextPlan"
            :header="false"
            :banner="false"
            :paid="false"
            :invoice="nextInvoice"
            :loaded="true"
            class="my-3"
          />
          <v-layout
            row
            wrap
            v-if="targetPlan === 'free' && currentPlan !== 'free'">
            <v-flex xs12>
              Your subscription will not be renewed when your current
              plan ends on {{ nextBillDate.toLocaleDateString() }}.
            </v-flex>
          </v-layout>
          <v-layout
            row
            wrap
            v-if="targetPlan!=='free' && nextPlan !== 'free' && currentPlan !== targetPlan">
            <v-flex xs12>
              Your new plan will take effect when your current plan
              ends. You will not be billed until then.
            </v-flex>
          </v-layout>
          <v-layout
            row
            wrap
            v-if="targetPlan===nextPlan && !ccField">
            <v-flex xs12>
              No changes needed.
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            v-if="inDialog"
            class="red--text darken-1"
            @click.native="reset(); $emit('input', false)">Cancel</v-btn>
          <v-btn
            v-else
            class="red--text darken-1"
            :disabled="!hasChange"
            @click.native="reset(); $emit('input', false)">Reset</v-btn>
          <!-- Disable if there are errors -->
          <v-btn
            class="green--text darken-1"
            @click.native="commitChange()"
            :loading="ccAdding"
            :disabled="ccAdding || hasError"
          >Confirm</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="processState === 'working'">
        <v-card-text class="text-xs-center py-5">
          <v-progress-circular
            indeterminate
            size=150
            class="primary--text"/>
          <h2 class="headline grey--text text--darken-1">Processing...</h2>
        </v-card-text>
      </v-card>
      <v-card v-if="processState === 'error'">
        <v-card-text class="text-xs-center">
          <v-icon
            color="error"
            size=150>warning</v-icon>
          <h2 class="headline grey--text text--darken-1">
            We encountered a problem...
          </h2>
          <h3>{{ errorMsg }}</h3>
        </v-card-text>
      </v-card>
      <v-card v-if="processState === 'complete'">
        <v-card-media
          height="110px"
          src="/static/welcome_header.png" >
          <v-container fill-height>
            <v-layout align-center>
              <v-flex text-xs-center>
                <h3 class="white--text display-3">Change Complete</h3>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-media>
        <v-card-text class="text-xs-center">
          <v-icon size=150>thumb_up</v-icon>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            v-if="inDialog"
            color="primary"
            @click="reset(); $emit('input', false)">
            Done
          </v-btn>
          <v-btn
            v-else
            color="primary"
            to="/documents">
            My Documents
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import * as VGrid from 'vuetify/es5/components/VGrid'
import * as VCard from 'vuetify/es5/components/VCard'
import * as VToolbar from 'vuetify/es5/components/VToolbar'
import * as VRadio from 'vuetify/es5/components/VRadioGroup'
import VAlert from 'vuetify/es5/components/VAlert'
import VBtn from 'vuetify/es5/components/VBtn'
import VCheckbox from 'vuetify/es5/components/VCheckbox'
import VSwitch from 'vuetify/es5/components/VSwitch'
import VIcon from 'vuetify/es5/components/VIcon'
import VProgressCircular from 'vuetify/es5/components/VProgressCircular'

import { mapActions, mapGetters, mapState } from 'vuex'
import Plans from '@/data/plans'

const Invoice = () => import('@/components/Invoice')

export default {
  props: {
    // For controlling display of "cancel" buttons and behavior
    inDialog: {
      type:    Boolean,
      default: false,
    },
    need: {
      type:    String,
      default: '',
    },
  },
  data () {
    return {
      acceptTOS:     false,
      cardError:     false,
      changeCC:      false,
      checkout:      false,
      confirmed:     false,
      noCCEntry:     true,
      processing:    false,
      stripeCard:    null,
      stripe:        null,
      stripeMounted: false,
      stripeLoaded:  false,
      targetPlan:    this.currentPlan,
    }
  },
  watch: {
    changeCC: function (to) {
      if (!to) {
        this.stripeCard.clear()
      }
    },
    processState: function (to) {
      if (to) { this.$ga.page(`/virtual/subscribe-${to}`) }
    },
  },
  mounted () {
    // if we're already open when mounted, need to run a lot of resets
    // if (this.value) {
    this.loadStripe()
    this.reset()
    // }
  },
  methods: {
    ...mapActions([
      'setStripeToken',
      'setWantedPlan',
      'setWantedPlanToken',
      'resetError',
      'clearStatus',
      'setError',
    ]),
    commitChange () {
      // 1 - check for errors
      if (this.hasError) {
        return
      }

      let base = Promise.resolve()
      if (this.userStatus != null) {
        base = base.then(() => {
          return this.clearStatus()
        })
      }

      // TODO - should disable the buttons and put up a spinner
      // 2 - add new cc if requested
      return base
        .then(() => {
          this.confirmed = true
          var newCC = (!this.hasCC || this.changeCC) && this.targetPlan !== 'free'
          var newPlan = this.targetPlan !== this.nextPlan
          if (newCC && newPlan) {
            return this.getToken().then(tokenId => {
              return this.setWantedPlanToken({
                plan:  this.targetPlan,
                token: tokenId,
              })
            })
          } else if (newCC && !newPlan) {
            return this.newToken()
          } else if (!newCC && newPlan) {
            return this.setWantedPlan(this.targetPlan)
          } else {
            return Promise.resolve('nothing to do')
          }
        }).catch(e => {
          this.setError(e.message)
          // should be for catching cc errors
        })
    },
    getToken () {
      return this.stripe.createToken(this.stripeCard, {
        owner: {
          name:  this.userName,
          email: this.userEmail,
        },
      }).then(result => {
        if (result.hasOwnProperty('token')) {
          return Promise.resolve(result.token.id)
        } else {
          this.cardError = result
          return Promise.reject(new Error(result))
        }
      })
    },
    newToken () {
      return this.getToken().then(tokenId => {
        return this.setStripeToken(tokenId)
      })
    },
    changePlan () {
      return this.setWantedPlan(this.targetPlan)
    },
    loadStripe () {
      if (window.Stripe == null && !document.querySelector('script[src="https://js.stripe.com/v3/"]')) {
        const script = document.createElement('script')
        script.src = 'https://js.stripe.com/v3/'
        script.onload = () => {
          this.stripeLoaded = true
          this.mountStripe()
        }
        document.body.appendChild(script)
      } else {
        this.stripeLoaded = true
      }
    },
    mountStripe () {
      // TODO: Check that we're at a point where we can mount...
      // I.E. only on the form page
      if (!this.stripeLoaded || this.stripeMounted) return

      this.stripe = window.Stripe(this.$root.stripePk)
      this.stripeCard = this.stripe.elements().create('card', {
        iconStyle: 'solid',
        style:     {
          base: {
            lineHeight: '36px',
            fontWeight: 300,
            fontSize:   '19px',
          },
          invalid: {},
        },
      })
      this.stripeCard.addEventListener('change', (event) => {
        if (event.empty) {
          this.noCCEntry = true
        } else {
          this.noCCEntry = false
        }
        this.resetError()
        if (event.error) {
          this.cardError = event.error
        } else {
          this.cardError = false
        }
        // console.log(event)
      })

      // TODO: this needs to more clever - many points where doesn't get mounted
      // Example: go to checkout, then return.
      const el = document.createElement('div')
      this.stripeCard.mount(el)
      document.getElementById('ccadd').appendChild(el)
      this.stripeMounted = true
    },
    reset () {
      this.checkout = false
      this.targetPlan = this.nextPlan === 'free'
        ? 'yearly-v1' : this.nextPlan
      this.noCCEntry = true
      this.changeCC = false
      this.acceptTOS = false
      // this.resetError()
      this.clearStatus()
      if (this.stripeCard) { this.stripeCard.clear() }
    },
  },
  computed: {
    ...mapState({
      userEmail:  state => state.user.user.email,
      userName:   state => state.user.user.displayName,
      userStatus: state => state.user.user.status,
      loading:    state => state.user.loading.user,
    }),
    ...mapGetters([
      'currentPlan',
      'userCard',
      'ccAdding',
      'billError',
      'paidStatus',
      'nextPlan',
      'nextBillDate',
      'planEndDate',
      'isWorking',
    ]),
    /**
     * State Management

    computed state
     - input    : checkout is false
     - checkout : checkout is true
     - working  : checkout is true & working is true
     - error    : checkout is true & error is true    [ 'Reset' => clears error, resets component ]
     - complete : checkout is true & working is false [ 'Close', 'Make a change' ]
    */
    processState: function () {
      if (!this.checkout) return 'form'
      else if (!this.confirmed) return 'checkout'
      else if (this.isWorking || this.userStatus == null) return 'working'
      else if (!this.isWorking && this.billError) return 'error'
      else return 'complete'
    },
    // If in a dialog OR currently not free
    // i.e. no reason to change to free
    showFree: function () {
      return this.currentPlan !== 'free'
    },
    /**
     * Form Validation
     */
    hasChange: function () {
      return (this.targetPlan !== this.nextPlan) ||
        (this.ccField && !this.noCCEntry)
    },
    hasError: function () {
      // return !!this.billError ||
      return this.cardError !== false ||
        (!this.acceptTOS && (this.ccField || this.targetPlan !== this.nextPlan)) ||
        (this.noCCEntry && this.ccField && this.targetPlan !== 'free')
    },
    errorMsg: function () {
      if (this.cardError !== false) {
        return this.cardError.message
        /**
      } else if (this.billError) {
        return this.billError
        **/
      } else if (this.ccField && this.noCCEntry && this.targetPlan !== 'free') {
        return 'A credit card is required'
      } else if (!this.acceptTOS) {
        return 'You must accept the Terms of Service and Privacy Policy.'
      } else {
        return ''
      }
    },
    hasCC: function () {
      return this.userCard.hasOwnProperty('id')
    },
    ccField: function () {
      return !this.hasCC || this.changeCC
    },
    /**
     * Content/header switches based on need/subscription status
     */
    needMessage: function () {
      switch (this.need) {
        case 'items':
          return 'To have more than 5 items and risks requires a subscription.'
        case 'docs':
          return 'To have more than 5 documents requires a subscription.'
        case 'sim':
          return 'Using the highest accuracy simulation requires a subscription.'
        case 'download':
          return 'Uploading and downloading requires a subscription.'
        case 'correlation':
          return 'Using correlations requires a subscription.'
        default:
          return 'You tried to perform an action which requires a subscription.'
      }
    },
    headline: function () {
      if (this.need) return 'Subscription Required'
      if (this.paidStatus) return 'Change Subscription'
      return 'Subscribe to DartCannon'
    },
    currentMessage: function () {
      if (this.currentPlan === 'free') return ''
      return `You are currently subscribed through
        ${(this.planEndDate || this.nextBillDate).toLocaleDateString()}.
        Any change will occur at that time.`
    },
    nextInvoice () {
      if (this.targetPlan === this.nextPlan) return {}

      const plan = Plans[this.targetPlan]

      const invoice = {}
      const start = this.planEndDate || this.nextBillDate || new Date()
      const end = new Date(
        start.valueOf() + (365 * plan.period / 12) * 3600000 * 24
      )
      invoice.date = start
      invoice.lines = [
        { id:           'new',
          description:  plan.long,
          amount:       plan.price,
          period_start: start,
          period_end:   end },
      ]

      invoice.total = plan.price
      invoice.amount_due = 10000

      return invoice
    },
  },
  components: {
    ...VGrid,
    ...VCard,
    ...VToolbar,
    ...VRadio,
    VAlert,
    VBtn,
    VCheckbox,
    VSwitch,
    VIcon,
    VProgressCircular,
    Invoice,
  },
}
</script>
<style>
.subcard {
  max-width: 650px;
}

#ccadd {
  width: 100%
}

.radio-group .input-group__details {
  display: none;
}

span.title {
  margin-bottom: 0px;
}

span.title .spacer {
  visibility: hidden;
}

.checkbox label {
  font-size: 11pt;
}
</style>
