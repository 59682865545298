// Copyright (C) 2017, 2018 DecElite LLC - All Rights Reserved. May not be distributed or copied.
export default {
  'monthly-v1': {
    active:  true,
    price:   1000,
    period:  1,
    short:   'Monthly',
    long:    'DartCannon Monthly Subscription',
    suggest: 'yearly-v1',

    features: [
      'Unlimited simulations',
      'Unlimited items in simulations',
      'Maximum simulations runs',
      'Import/export',
    ],
  },
  'yearly-v1': {
    active: true,
    price:  10000,
    period: 12,
    short:  'Yearly',
    long:   'DartCannon Yearly Subscription',

    features: [
      'Unlimited simulations',
      'Unlimited items in simulations',
      'Maximum simulations runs',
      'Import/export',
    ],
  },
  'free': {
    active: true,
    price:  0,
    period: 0,
    short:  'Free',
    long:   'DartCannon Free Subscription',

    features: [
      'Limited to 5 saved simulations',
      'Limited to 5 items in simulations',
      'Limited number of simulation runs',
      'No import/export',
    ],
  },
}
